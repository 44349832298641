import * as microsoftTeams from '@microsoft/teams-js';

export const isTeams = () => {
  const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  return !!(
    (window.parent === window.self && window.nativeInterface) ||
    window.navigator.userAgent.includes('Teams/') ||
    window.name === 'embedded-page-container' ||
    window.name === 'extension-tab-frame'
  );
};
